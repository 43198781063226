/* 
 * Copyright (C) The Association of Former Students - All Rights Reserved
 * Unauthorized copying of this code, via any medium is strictly prohibited
 * Proprietary and confidential
 */


require('./scss/site-theme.scss');
require('typeface-open-sans');
require('./css/nucleo/css/nucleo.css');
require('font-awesome/css/font-awesome.css');
const $ = require('jquery');
global.$ = global.jQuery = $;

require('popper.js');
require('bootstrap');
require('headroom.js');
require('onscreen');
require('bootstrap-datepicker');
require('./js/argon-theme.js');
